// Generated by Framer (e39ee10)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KLuQZIeIX"];

const serializationHash = "framer-aBbRT"

const variantClassNames = {KLuQZIeIX: "framer-v-mml5mv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({amount, background2, fontSize, height, id, numberColor, opacity, width, ...props}) => { return {...props, nQQzyBxOE: fontSize ?? props.nQQzyBxOE ?? 96, OwFzbvmVl: numberColor ?? props.OwFzbvmVl ?? "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0))", tIZdY5mnV: background2 ?? props.tIZdY5mnV ?? "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51))", Wfy1fbFYj: opacity ?? props.Wfy1fbFYj ?? 1, WGC_98GQ6: amount ?? props.WGC_98GQ6 ?? "4"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;amount?: string;numberColor?: string;fontSize?: number;opacity?: number;background2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WGC_98GQ6, OwFzbvmVl, nQQzyBxOE, Wfy1fbFYj, tIZdY5mnV, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KLuQZIeIX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-mml5mv", className, classNames)} data-border data-framer-name={"First"} layoutDependency={layoutDependency} layoutId={"KLuQZIeIX"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: tIZdY5mnV, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QmlnIFNob3VsZGVycyBUZXh0LTcwMA==", "--framer-font-family": "\"Big Shoulders Text\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-nQQzyBxOE-btxW2geRM) * 1px)", "--framer-font-weight": "700", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-OwFzbvmVl-btxW2geRM))"}}>4</motion.p></React.Fragment>} className={"framer-1cllj3u"} fonts={["GF;Big Shoulders Text-700"]} layoutDependency={layoutDependency} layoutId={"eXujo0Hrl"} style={{"--extracted-r6o4lv": "var(--variable-reference-OwFzbvmVl-btxW2geRM)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-nQQzyBxOE-btxW2geRM": nQQzyBxOE, "--variable-reference-OwFzbvmVl-btxW2geRM": OwFzbvmVl, opacity: Wfy1fbFYj}} text={WGC_98GQ6} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aBbRT.framer-ojfe5i, .framer-aBbRT .framer-ojfe5i { display: block; }", ".framer-aBbRT.framer-mml5mv { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 280px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 280px; }", ".framer-aBbRT .framer-1cllj3u { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-aBbRT.framer-mml5mv { gap: 0px; } .framer-aBbRT.framer-mml5mv > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-aBbRT.framer-mml5mv > :first-child { margin-left: 0px; } .framer-aBbRT.framer-mml5mv > :last-child { margin-right: 0px; } }", ".framer-aBbRT[data-border=\"true\"]::after, .framer-aBbRT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 280
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WGC_98GQ6":"amount","OwFzbvmVl":"numberColor","nQQzyBxOE":"fontSize","Wfy1fbFYj":"opacity","tIZdY5mnV":"background2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbtxW2geRM: React.ComponentType<Props> = withCSS(Component, css, "framer-aBbRT") as typeof Component;
export default FramerbtxW2geRM;

FramerbtxW2geRM.displayName = "404 Layers";

FramerbtxW2geRM.defaultProps = {height: 280, width: 280};

addPropertyControls(FramerbtxW2geRM, {WGC_98GQ6: {defaultValue: "4", displayTextArea: false, title: "Amount", type: ControlType.String}, OwFzbvmVl: {defaultValue: "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0))", title: "Number Color", type: ControlType.Color}, nQQzyBxOE: {defaultValue: 96, title: "Font Size", type: ControlType.Number}, Wfy1fbFYj: {defaultValue: 1, max: 1, min: 0, step: 0.01, title: "Opacity", type: ControlType.Number}, tIZdY5mnV: {defaultValue: "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51)) /* {\"name\":\"Lime/60\"} */", title: "Background 2", type: ControlType.Color}} as any)

addFonts(FramerbtxW2geRM, [{explicitInter: true, fonts: [{family: "Big Shoulders Text", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Wit3TcPNl6P2pc.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})